















import { Component, Prop, Vue } from 'vue-property-decorator'

interface Widget {
  component: () => object
  initialData: null | string
  parsedInitialData: null | object
  posInMainFeed: number
  props: string
  parsedProps: object
  showOnMobile: boolean
  title: string
}

@Component
export default class SidebarWidget extends Vue {
  @Prop(Object) readonly widget!: Widget
  @Prop({ type: Boolean, default: false }) readonly inSidebar!: boolean

  get theWidget(): Widget {
    return {
      ...this.widget,
      parsedProps: JSON.parse(this.widget.props),
      parsedInitialData:
        this.widget.initialData !== null
          ? JSON.parse(this.widget.initialData)
          : null,
      component: () => {
        return import(
          `~components/sidebar/widgets/${this.widget.component}.vue`
        ).catch(() => console.error('no such component', this.widget.component))
      },
    }
  }

  get showWidget(): boolean {
    return (
      !(this.inSidebar && this.props.hideInSidebar === true) &&
      !(!this.inSidebar && this.props.hideInContent === true)
    )
  }

  get component(): object {
    return this.theWidget.component
  }

  get props(): { [key: string]: any } {
    return this.theWidget.parsedProps
  }

  get initialData(): null | object {
    return this.theWidget.parsedInitialData
  }

  get title(): string {
    return this.theWidget.title
  }
}
