import { ActionTree, GetterTree, MutationTree, Store } from 'vuex'
import { interpret } from 'xstate'
import { addAction, addFilter } from '@russmedia/hooks'
import {
  appPaywallMachine,
  AppPaywallMachineEvent,
  setActivationContext,
} from '~base/statemachines/AppPaywallMachine'
import { PianoCustomVariables, ApaLoginParams } from '~base/common/types'

interface AppPaywallMachineEventWithParams {
  event: AppPaywallMachineEvent
  params?: any
}

export enum AppDeviceType {
  iOS = 'iOS',
  Android = 'Android',
  web = 'web',
}

const openAPALink = (url: string) => {
  if (`Cypress` in window) {
    url = `/toAPAApp/${url}`
  }
  window.location.href = url
}

const appLog = (message: string, ...rest: any[]): void => {
  console.log(`📱 ${message}`, ...rest)
}

const initStateMachine = () =>
  interpret(
    appPaywallMachine.withConfig({
      actions: {
        close() {
          window.$nuxt.$store.dispatch('app/closeAuthView')
        },
        setActivationContext,
      },
    })
  )

const initActionsAndFilters = (store: Store<RootState>) => {
  addAction('piano/pre-initialize', async () => {
    appLog('piano/pre-initialize called')
    if (store.getters['app/doAutoLogout']) {
      appLog(`clearing subscriber data due to autologout in App`)
      await store.dispatch('paywall/clearSubscriberData', null, { root: true })
      await store.dispatch('app/unauthorizeApp')
    }
  })

  addFilter('piano/customTags', (tags: string[]) => {
    tags.push('app')
    return tags
  })

  addAction('piano/checkoutStateChange', async (data) => {
    await store.dispatch('app/handleCheckoutStateChanged', data)
  })

  addAction('paywall/loginSuccess', async () => {
    await store.dispatch('app/authorizeApp')
  })

  addFilter('piano/loginRequiredHandler', () => 'app/openAuthView')
  addFilter('piano/onLoginFn', (_: () => void, ...rest: any[]) => {
    return () => {
      const { dispatch } = rest[0] as Store<any>
      dispatch(`app/openAuthView`)
    }
  })
  addFilter('piano/onRegisterFn', (_: () => void, ...rest: any[]): any => {
    return () => {
      const { dispatch } = rest[0] as Store<any>
      dispatch(`app/openRegistrationView`)
    }
  })

  addFilter('piano/customEventHandler', () => 'app/handleCustomEvent')
  addFilter('piano/checkoutCloseHandler', () => 'app/checkoutComplete')
  addFilter(
    'piano/customVariables',
    (customVariables: PianoCustomVariables[]) => {
      return [...customVariables, { name: 'inApp', value: 1 }]
    }
  )
}

let paywallService = initStateMachine()

export const state = () => ({
  platform: AppDeviceType.web as AppDeviceType,
  deviceType: '',
  version: '',
  isTestApp: false,
  isAuthViewShowing: false,
  query: {} as any,
  paywallState: null as any,
  autoLogout: false,
  paywallInitialized: false,
  offerInAppPurchases: undefined as undefined | boolean,
  handleInBrowser: false,
  lastKnownLoginData: {
    username: null as string | null,
    password: null as string | null,
  },
  isAppDebugOpen: false,
  isAuthorized: false,
  androidWhitelist: [] as string[],
  iosWhitelist: [] as string[],
})

export type RootState = ReturnType<typeof state>

export const actions: ActionTree<RootState, RootState> = {
  async initialize({ commit, dispatch, rootGetters }) {
    const $cookies = this.$cookies
    const { context } = this.app
    let { clientVersion, deviceType, os } = context.route.query
    commit('SET_QUERY', context.route.query)

    if (clientVersion) {
      $cookies.set('__app', JSON.stringify({ clientVersion, deviceType, os }), {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
        domain: this.app.$config.pianoCookieDomain,
      })

      // see if we should outlogout
      const { aboNo } = context.route.query
      if (aboNo === undefined) {
        commit('SET_AUTOLOGOUT')
      }
    } else {
      // try and fetch the data from $cookies
      const storedAppData = $cookies.get('__app')
      if (typeof storedAppData === 'object') {
        clientVersion = storedAppData.clientVersion
        deviceType = storedAppData.deviceType
        os = storedAppData.os
      }
    }

    // get whitelist settings from frontpage
    const { androidWhitelist, iosWhitelist } = rootGetters['frontpage/settings']
    commit('SET_ANDROID_WHITELIST', androidWhitelist)
    commit('SET_IOS_WHITELIST', iosWhitelist)

    commit('SET_VERSION', clientVersion)
    commit('SET_DEVICE_TYPE', deviceType)
    commit('SET_PLATFORM', os)
    await dispatch('queryAuthorizationStatus')
  },
  initializeClient({ commit, getters }) {
    if (!getters.isApp) return
    console.log(`📱 initializing client`)
    initActionsAndFilters(this)

    const handleInBrowser = localStorage.getItem('__app_handle_in_browser')
    if (handleInBrowser) commit('HANDLE_IN_BROWSER', true)
    const isAppDebugOpen = localStorage.getItem('__app_debug_open')
    if (isAppDebugOpen) commit('APP_DEBUG_OPEN', true)
  },
  async autoLogin({ dispatch, rootGetters }) {
    const { context } = this.app
    const { aboNo, aboPassword } = context.route.query
    console.log(
      `📱 autoLogin`,
      aboNo,
      aboPassword,
      rootGetters['paywall/isLoggedIn']
    )

    if (aboNo && aboPassword && !rootGetters['paywall/isLoggedIn']) {
      await dispatch(
        'paywall/login',
        {
          username: aboNo,
          password: aboPassword,
          loginFromApp: true,
        },
        { root: true }
      )
    }
    if (rootGetters['paywall/isLoggedIn']) {
      await dispatch('authorizeApp')
    }
  },
  initializePaywallState({ commit, dispatch }, toState: string = '') {
    commit('PAYWALL_INITIALIZED')
    commit(
      'OFFER_IN_APP_PURCHASES',
      this.app.context.query.issueDate !== undefined
    )
    paywallService
      .onTransition((state: any) => {
        commit('SET_PAYWALL_STATE', state)
      })
      .start()
    commit('SET_PAYWALL_STATE', appPaywallMachine.initialState)

    if (toState === '') {
      const route = this.app.context.route

      appLog(`cookies`, this.$cookies.getAll())
      if (route.query.aboNo || route.query.errorCode) {
        dispatch('sendPaywallEvent', { event: 'SHOW_OFFER' })
      } else {
        dispatch('sendPaywallEvent', { event: 'ONBOARDING' })
      }
    } else {
      dispatch('sendPaywallEvent', { event: toState })
    }
  },
  sendPaywallEvent({ commit }, event) {
    commit('SEND_EVENT', event)
  },
  setAuthViewShowing({ commit }, isShowing: boolean) {
    commit('SET_AUTH_VIEW_SHOWING', isShowing)
  },
  openAuthView({ dispatch, getters }, data) {
    this.$cookies.set('__pianoParams', data)

    if (!getters.authViewShowing) {
      if (getters.handleInBrowser === false) {
        dispatch('setAuthViewShowing', true)
        openAPALink(`MPS.showAuthView://`)
      } else {
        let qs = `?`
        const params = this.app.context.route.query
        for (const key in params) {
          qs += `${key}=${encodeURIComponent(
            params[key as keyof ApaLoginParams] as string
          )}&`
        }
        window.open(`/app/login${qs}`, '_blank')
      }
    }
  },
  openRegistrationView({ dispatch }) {
    this.$cookies.set(`__app_register`, 1, {
      path: '/',
      maxAge: 20,
      domain: this.app.$config.pianoCookieDomain,
    })
    // wait a little bit, as it seems the cookie is not always present
    window.setTimeout(() => dispatch('openAuthView'), 100)
  },
  closeAuthView({ dispatch, getters }) {
    dispatch('setAuthViewShowing', false)
    this.$cookies.set(`lastPaywallState`, 1, {
      path: '/',
      maxAge: 20,
      domain: this.app.$config.pianoCookieDomain,
    })
    if (getters.handleInBrowser === false) {
      openAPALink('MPS.close://')
    } else {
      window.close()
    }
  },
  checkoutComplete({ dispatch }) {
    dispatch('loginWithAPA')
  },
  setLastKnownLoginData(
    { commit },
    data: { username: string; password: string }
  ) {
    commit('SET_LAST_KNOWN_LOGIN_DATA', data)
  },
  loginWithAPA({ getters }) {
    appLog(`Logging in with APA`)
    if (getters.handleInBrowser === false) {
      const { username, password } = getters.lastKnownLoginData
      const params: ApaLoginParams = {
        aboNo: username,
        aboPassword: password,
      }
      const q = this.app.context.route.query
      if (q.issueMutation) params.issueMutation = q.issueMutation as string
      if (q.issueDate) params.issueDate = q.issueDate as string
      if (q.issueId) params.issueId = q.issueId as string
      let qs = `?`
      for (const key in params) {
        qs += `${key}=${encodeURIComponent(
          params[key as keyof ApaLoginParams] as string
        )}&`
      }
      openAPALink(`MPS.checkAboUserAndLogin://${qs}`)
    } else {
      window.close()
    }
  },
  resetStateMachine({ dispatch }, toState: string = '') {
    paywallService = initStateMachine()
    dispatch('initializePaywallState', toState)
  },
  handleCustomEvent({ dispatch, rootGetters }, data) {
    appLog(`handleCustomEvent dispatched`, data)
    if (data.eventName && data.eventName.indexOf('offer-') === 0) {
      // if the user is logged in, piano will trigger further steps
      if (!rootGetters['paywall/isLoggedIn'])
        dispatch('sendPaywallEvent', { event: 'REGISTER' })
    } else {
      dispatch('paywall/handleCustomEvent', data, { root: true })
    }
  },
  handleCheckoutStateChanged({ dispatch }, newState) {
    appLog(`handleCheckoutStateChanged`, newState)
    if (newState === 'offer') {
      dispatch('sendPaywallEvent', { event: 'SHOW_OFFER' })
    }
  },
  async authorizeApp({ getters, rootGetters }) {
    const userRef = rootGetters['paywall/getUserRef']
    const udid = getters.data.query.udid
    const mutation = require('~base/graphql/mutations/AuthorizeApp.gql')
    try {
      const apolloClient = this?.app?.apolloProvider?.defaultClient
      await apolloClient?.mutate({
        mutation,
        variables: {
          udid,
          userRef,
        },
      })
    } catch (e) {
      appLog(`error authenticing app`, e)
    }
  },
  async unauthorizeApp({ getters }) {
    const udid = getters.data.query.udid
    const mutation = require('~base/graphql/mutations/UnauthorizeApp.gql')
    try {
      const apolloClient = this?.app?.apolloProvider?.defaultClient
      await apolloClient?.mutate({
        mutation,
        variables: {
          udid,
        },
      })
    } catch (e) {
      appLog(`error unauthenticating app`, e)
    }
  },
  async queryAuthorizationStatus({ getters, commit, dispatch }) {
    if (!getters.data.query.udid) return
    const query = require('~base/graphql/queries/IsAppAuthorized.gql')
    try {
      const apolloClient = this?.app?.apolloProvider?.defaultClient
      const response = await apolloClient?.query({
        query,
        variables: {
          udid: getters.data.query.udid,
        },
      })
      commit('SET_APP_AUTHORIZED', response?.data.isAppAuthorized.authorized)
      dispatch('paywall/setUserRef', response?.data.isAppAuthorized.userRef, {
        root: true,
      })
      return
    } catch (e) {
      appLog(`Couldn't get authorization status`, e)
    }
    commit('SET_APP_AUTHORIZED', false)
  },
}

export const mutations: MutationTree<RootState> = {
  SET_QUERY(currentState, query) {
    currentState.query = query
  },
  SET_VERSION(currentState, clientVersion) {
    if (!clientVersion) {
      currentState.version = ''
      return
    }
    const matches = clientVersion.match(
      /[VN|NEUE|VorarlbergerNachrichten|NVTZ](Test)?(_Android)?\/(\d+)\/\d+$/i
    )
    if (matches !== null) {
      currentState.isTestApp = matches[1] === 'Test'
      currentState.version = matches[3]
    }
  },
  SET_DEVICE_TYPE(currentState, deviceType) {
    currentState.deviceType = deviceType || ''
  },
  SET_PLATFORM(currentState, os: AppDeviceType | undefined) {
    currentState.platform = os ? AppDeviceType[os] : AppDeviceType.web
  },
  SET_AUTH_VIEW_SHOWING(currentState, isShowing: boolean) {
    currentState.isAuthViewShowing = isShowing
  },
  SET_PAYWALL_STATE(currentState, state) {
    currentState.paywallState = state
  },
  SEND_EVENT(_, event: AppPaywallMachineEventWithParams) {
    const eventName = event.event
    const params = event.params
    appLog(`SEND_EVENT`, eventName, params)
    paywallService.send(eventName, params)
  },
  SET_AUTOLOGOUT(currentState) {
    currentState.autoLogout = true
  },
  PAYWALL_INITIALIZED(currentState) {
    currentState.paywallInitialized = true
  },
  OFFER_IN_APP_PURCHASES(currentState, offerInAppPurchases) {
    currentState.offerInAppPurchases = offerInAppPurchases
  },
  HANDLE_IN_BROWSER(currentState, handleInBrowser) {
    localStorage.removeItem('__app_handle_in_browser')
    if (handleInBrowser) localStorage.setItem('__app_handle_in_browser', '1')
    currentState.handleInBrowser = handleInBrowser
  },
  SET_LAST_KNOWN_LOGIN_DATA(currentState, lastKnownLoginData) {
    currentState.lastKnownLoginData = lastKnownLoginData
  },
  APP_DEBUG_OPEN(currentState, isAppDebugOpen) {
    localStorage.removeItem('__app_debug_open')
    if (isAppDebugOpen) localStorage.setItem('__app_debug_open', '1')
    currentState.isAppDebugOpen = isAppDebugOpen
  },
  SET_APP_AUTHORIZED(currentState, isAuthorized) {
    currentState.isAuthorized = isAuthorized
  },
  SET_ANDROID_WHITELIST(currentState, androidWhitelist) {
    currentState.androidWhitelist = (androidWhitelist || '').split(/\s+/)
  },
  SET_IOS_WHITELIST(currentState, iosWhitelist) {
    currentState.iosWhitelist = (iosWhitelist || '').split(/\s+/)
  },
}

export const getters: GetterTree<RootState, RootState> = {
  isApp: ({ platform }) =>
    platform === AppDeviceType.iOS || platform === AppDeviceType.Android,
  data: (currentState) => currentState,
  isTestApp: ({ isTestApp }) => isTestApp,
  deviceType: ({ deviceType }) => deviceType,
  platform: ({ platform }) => platform,
  isWhitelisted: ({
    version,
    isTestApp,
    platform,
    androidWhitelist,
    iosWhitelist,
  }) =>
    isTestApp ||
    (platform === 'Android'
      ? androidWhitelist.includes(version)
      : iosWhitelist.includes(version)),
  authViewShowing: ({ isAuthViewShowing }) => isAuthViewShowing,
  currentPaywallState: ({ paywallState }) => paywallState,
  doAutoLogout: ({ autoLogout }) => autoLogout,
  isPaywallInitialized: ({ paywallInitialized }) => paywallInitialized,
  offerInAppPurchases: ({ offerInAppPurchases }) => offerInAppPurchases,
  handleInBrowser: ({ handleInBrowser }) => handleInBrowser,
  lastKnownLoginData: ({ lastKnownLoginData }) => lastKnownLoginData,
  isAppDebugOpen: ({ isAppDebugOpen }) => isAppDebugOpen,
  isAuthorized: ({ isAuthorized }) => isAuthorized,
}
