export const state = () => ({
  from: undefined,
})

const mapCategoryToOewa = (category) => {
  const oewaMappings = {
    politik: 'RedCont/Politik/Politikueberblick/politik',
    vorarlberg: 'RedCont/Nachrichten/LokaleNachrichten/vorarlberg',
    sport: 'RedCont/Sport/Sportueberblick/sport',
    leserbriefe: 'RedCont/Sonstiges/Sonstiges/leserbriefe',
    menschen: 'RedCont/Nachrichten/GesellschaftUndLeute/menschen',
    markt: 'RedCont/Wirtschaft/Wirtschaftsueberblick/markt',
    kultur: 'RedCont/KulturUndFreizeit/KulturUeberblick/kultur',
    welt: 'RedCont/Nachrichten/Chronik/welt',
    motor: 'RedCont/AutoUndMotor/AutoUndMotor/motor',
    wissen: 'RedCont/Nachrichten/Nachrichtenueberblick/wissen',
    reise: 'RedCont/Reisen/Reiseueberblick/reise',
    gesund: 'RedCont/Gesundheit/Gesundheitsratgeber/gesund',
    wohin: 'RedCont/KulturUndFreizeit/Veranstaltungen/wohin',
    spezial: 'RedCont/Nachrichten/Nachrichtenueberblick/spezial',
    vorteil: 'UnterhaltungGames/Games/Gewinnspiele/vorteilsclub',
    sonderthemen: 'RedCont/Nachrichten/Nachrichtenueberblick/sonderthemen',
    extra: 'RedCont/Sonstiges/Sonstiges/extra',
  }
  if (Object.prototype.hasOwnProperty.call(oewaMappings, category)) {
    return oewaMappings[category]
  }
  return 'RedCont/Nachrichten/Chronik'
}

export const mutations = {
  COMING_FROM(currentState, payload) {
    currentState.from = payload
  },
}
export const actions = {
  trackHomepage({ dispatch }) {
    dispatch('sendToOewa', { cp: `RedCont/Homepage/Homepage` })
    dispatch('sendToCB', {
      path: `/`,
    })
    dispatch('sendToFB')
    dispatch('trackPageType', 'overview')
    dispatch('trackVirtualPageview', {
      virtualPagePath: '/',
    })
  },
  trackRessortPage({ dispatch }, category) {
    const data = { cp: mapCategoryToOewa(category) }
    dispatch('sendToOewa', data)
    dispatch('sendToCB', { sections: category })
    dispatch('sendToFB')
    dispatch('trackPageType', 'category')
    dispatch('trackVirtualPageview', {
      virtualPagePath: `/ressort/${category}`,
    })
  },
  trackSearchPage({ dispatch }, query) {
    dispatch('sendToOewa', { cp: 'Service/Suchmaschinen/allgemeineSuche' })
    dispatch('sendToCB', { path: `/suche/${query}` })
    dispatch('sendToFB')
    dispatch('trackPageType', 'search')
    dispatch('trackVirtualPageview', { virtualPagePath: `/suche/${query}` })
  },
  trackOtherPage({ dispatch }, page) {
    dispatch('sendToOewa', { cp: 'RedCont/Sonstiges/Sonstiges' })
    dispatch('sendToCB', { path: `/${page.slug}` })
    dispatch('sendToFB')
    dispatch('trackPageType', 'other')
    dispatch('trackVirtualPageview', { virtualPagePath: `/${page.slug}` })
  },
  trackPaywallAction({ dispatch, rootGetters }, action) {
    const currentPost = rootGetters['news/currentPost']
    dispatch('trackGeneric', {
      category: 'paywall',
      action,
      label: currentPost ? currentPost.title : document.title,
    })
    if (action === 'testclick') {
      dispatch('trackVirtualPageview', {
        virtualPagePath: '/checkout/form',
        virtualPageTitle: '/Checkout - Form',
        force: true,
        toOptimize: false,
      })
      dispatch('pushToDataLayer', {
        event: 'optimize.vnpaywallform',
        vnpageview: 'false',
      })
      dispatch('pushToDataLayer', {
        event: 'optimize.vnpaywallform',
        vnpageview: 'true',
      })
    }
  },
  trackGeneric(
    { getters, dispatch },
    {
      category,
      action,
      label = undefined,
      value = undefined,
      nonInteraction = false,
    }
  ) {
    if (process.client) {
      const trackingObj = {
        event: 'genericEvent',
        category,
        action,
        label,
        value,
        nonInteraction,
        ...getters.viewer,
      }
      dispatch('pushToDataLayer', trackingObj)
    }
  },
  trackArticleView({ getters, dispatch }, payload) {
    if (process.client) {
      const [cat] = payload.post.relevantCategories
      const category = cat ? cat.slug : 'ohne-kategorie'
      const data = {
        cp: mapCategoryToOewa(category),
      }
      dispatch('sendToOewa', data)

      const author = payload.post.author
      dispatch('sendToCB', {
        sections: payload.post.categories.nodes.map((n) => n.slug).join(', '),
        authors: author?.node.name || '',
        path: payload.post.link,
        title: payload.post.title,
      })

      dispatch('trackPageType', 'article')
      dispatch('trackVirtualPageview', {
        virtualPagePath: payload.post.link,
        virtualPageTitle: payload.post.title,
      })
      const trackingObj = {
        event: 'eec.detailview',
        ecommerce: payload.ga,
        ...getters.viewer,
        nonInteraction: true,
        label: payload.post.title,
        author: payload.post.author?.node?.name || '',
        isFree: payload.post.isFree !== false,
        pageCategories: payload.post.categories.nodes
          .map((n) => n.slug)
          .join(', '),
        pageTags: payload.post.tags.nodes.map((n) => n.slug).join(', '),
      }
      dispatch('pushToDataLayer', trackingObj)
    }
  },
  trackArticleAppearence({ getters, dispatch }, payload) {
    if (process.client) {
      const trackingObj = {
        event: 'eec.impression',
        ecommerce: payload,
        ...getters.viewer,
      }
      dispatch('pushToDataLayer', trackingObj)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackPageType({ getters, dispatch }, pageType) {
    const trackingObj = {
      pageType,
      ...getters.viewer,
    }
    dispatch('pushToDataLayer', trackingObj)
  },
  trackVirtualPageview(
    { getters, dispatch },
    { virtualPagePath, virtualPageTitle, force, toOptimize = true }
  ) {
    // do not track a virtualPageview if it's a hard refresh
    if (!getters.from && !force) {
      if (toOptimize) {
        dispatch('pushToDataLayer', {
          event: 'optimize.vnpageview',
          vnpageview: 'true',
        })
      }
      return
    }
    window.setTimeout(() => {
      virtualPageTitle = virtualPageTitle || document.title

      const virtualPageview = {
        event: 'virtualPageview',
        virtualPagePath,
        virtualPageTitle,
        ...getters.viewer,
      }
      dispatch('pushToDataLayer', virtualPageview)
      if (toOptimize) {
        dispatch('pushToDataLayer', {
          event: 'optimize.vnpageview',
          vnpageview: 'false',
        })
        dispatch('pushToDataLayer', {
          event: 'optimize.vnpageview',
          vnpageview: 'true',
        })
      }
    }, 500)
  },
  pushToDataLayer(_, payload) {
    if (window.dataLayer) {
      console.log(`🔍`, payload)
      window.dataLayer.push(payload)
    }
  },
  comingFrom({ commit }, payload) {
    commit('COMING_FROM', payload)
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendToOewa({ commit }, pixelData) {
    const isMobile = /android|iPad|iPhone|iPod/i.test(navigator.userAgent)
    const oewaPixel = {
      cn: 'at',
      st: 'at_w_atvn',
      cp: 'RedCont/Sonstiges/Sonstiges',
      sv: isMobile ? 'mo' : 'in',
      ps: 'lin',
      sc: 'yes',
      ...pixelData,
    }
    oewaPixel.cp = `${oewaPixel.cp}${isMobile ? '/moewa' : ''}/VN`

    if (process.client) {
      // new implementation - PAPER-138
      if (typeof window.IOMm === 'function') {
        IOMm('configure', {
          st: 'at_w_atvn',
          dn: 'data-be4fd7bada.vn.at',
          cn: 'at',
          mh: 5,
        }) // Configure IOMm
        IOMm('pageview', { cp: oewaPixel.cp }) // Count pageview
      }
    }
  },
  sendToCB({ getters }, virtualPageData) {
    if (process.client && window.pSUPERFLY) {
      window._cbq = window._cbq || []
      const accountData = ['_acct', getters.viewer.canRead ? 'paid' : 'anon']
      window._cbq.push(accountData)

      virtualPageData = {
        sections: '',
        authors: '',
        title: document.title,
        path: location.pathname,
        ...virtualPageData,
      }
      console.log(`🏁`, accountData, virtualPageData)
      window.pSUPERFLY.virtualPage(virtualPageData)
    }
  },
  sendToFB() {
    if (process.client && window.fbq) {
      window.fbq('track', 'PageView')
    }
  },
}

export const getters = {
  viewer: (_state, _getters, _rootState, rootGetters) => {
    let viewerData = {
      isLoggedIn: rootGetters['paywall/isLoggedIn'],
      canRead: !rootGetters['paywall/displayShortenedContent'],
    }
    const subscriber = rootGetters['paywall/subscriber']

    let aboArt = ''
    let aboExpiration = ''
    if (subscriber.accessList && subscriber.accessList.length > 0) {
      const activeAbos = subscriber.accessList.filter((item) => !item.expired)
      aboArt = activeAbos.map((item) => item.resourceName).join('_')
      aboExpiration =
        activeAbos.length > 0
          ? activeAbos.sort(
              (a, b) => a.daysUntilExpiration > b.daysUntilExpiration
            )[0].daysUntilExpiration
          : null
    }
    if (subscriber && subscriber.uid) {
      viewerData = {
        ...viewerData,
        userid: subscriber.uid,
        canReadOnlyVNAt: subscriber.canReadOnlyVNAt,
        dimension12: subscriber.uid,
        aboart: aboArt,
        aboexpiration: aboExpiration,
      }
    }
    return viewerData
  },
  from: ({ from }) => from,
}
