import { doAction, applyFilters } from '@russmedia/hooks'
import { paywallLog, paywallError } from '~plugins/piano.js'

const getValidACLEntries = (accessList = []) => {
  return accessList.filter(
    (acl) => acl.expireDate === -1 || acl.daysUntilExpiration >= 0
  )
}
const isUserExpired = (accessList = []) => {
  return getValidACLEntries(accessList).length === 0
}

const performLogin = async (
  { commit, dispatch },
  username,
  password,
  loginFromApp,
  config
) => {
  commit('FETCHING_DATA', true)
  let json
  try {
    const res = await fetch(`${config.paywallEndpoint}/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
    })
    commit('FETCHING_DATA', false)
    json = await res.json()
    await dispatch('handlePaywallResponse', {
      ...json,
      loginFromApp,
    })
  } catch (err) {
    commit('FETCHING_DATA', false)
    err.jsonData = json
    throw err
  }
}

const performActivation = async ({ commit, dispatch }, data, config) => {
  commit('FETCHING_DATA', true)

  let json
  const loginFromApp = data.loginFromApp
  delete data.loginFromApp
  try {
    const res = await fetch(`${config.paywallEndpoint}/register_abocard`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
    commit('FETCHING_DATA', false)
    json = await res.json()
    await dispatch('handlePaywallResponse', {
      ...json,
      loginFromApp,
    })
  } catch (err) {
    commit('FETCHING_DATA', false)
    err.jsonData = json
    throw err
  }
}

export const state = () => ({
  enabled: true,
  loggedIn: false,
  subscriber: {},
  meterData: undefined,
  shortenContent: false,
  showingOffer: false,
  showOfferContainer: true,
  experience: {},
  displayName: undefined,
  showLoginForm: false,
  showRegistrationForm: false,
  showForgotPasswordForm: false,
  showVOLIntegration: false,
  showData: false,
  showAbocardActivation: false,
  aboCardActivationData: null,
  showUpsell: false,
  showEmailHint: false,
  fetchingData: false,
  userRef: undefined,
  capabilities: {},
  onSuccessCallback: undefined,
  displayMode: undefined,
  isBot: false,
  prefillRegistrationData: {},
  aboNo: undefined,
})

export const mutations = {
  SET_PAYWALL_ENABLED(currentState, enabled) {
    currentState.enabled = enabled
  },
  SET_METER_DATA(currentState, data) {
    currentState.meterData = data
  },
  SET_SHORTEN_CONTENT(currentState, shortenContent = true) {
    currentState.shortenContent = shortenContent
  },
  SET_METER_EXPIRED(currentState, isExpired = true) {
    paywallLog(`setting meter expired`, isExpired)
    mutations.SET_SHORTEN_CONTENT(currentState, isExpired)
  },
  SHOWING_OFFER(currentState, showing = true) {
    mutations.SET_METER_EXPIRED(currentState, showing)
    currentState.showingOffer = showing
  },
  SET_EXPERIENCE(currentState, experience) {
    currentState.experience = experience
  },
  SET_SUBSCRIBER(currentState, subscriber) {
    let displayName
    paywallLog('subscriber', subscriber)
    localStorage.removeItem('paywall/subscriber')
    if (subscriber && subscriber.uid !== 'anon') {
      mutations.SET_LOGGED_IN(currentState, true)
      if (subscriber.expired) {
        paywallLog('subscriber has expired')
        mutations.SET_METER_EXPIRED(currentState, true)
      } else {
        paywallLog('subscriber is OK')
        mutations.SET_METER_EXPIRED(currentState, false)
      }
      const aclResources = getValidACLEntries(subscriber.accessList).map(
        (i) => i.resourceName
      )
      subscriber.canReadOnlyVNAt =
        aclResources.includes('VN.AT') && aclResources.length === 1

      currentState.subscriber = {
        ...subscriber,
      }
      localStorage.setItem(
        'paywall/subscriber',
        JSON.stringify({ ...subscriber })
      )
      const displayFirstname = subscriber.firstName || ''
      const displayLastname = subscriber.lastName || ''
      displayName = `${displayFirstname} ${displayLastname}`.trim()
    } else {
      mutations.SET_LOGGED_IN(currentState, false)
    }
    mutations.SET_DISPLAY_NAME(currentState, displayName)
  },
  SET_LOGGED_IN(currentState, loggedIn) {
    currentState.loggedIn = loggedIn
    if (loggedIn) {
      localStorage.setItem('paywall/loggedIn', 1)
    } else {
      localStorage.removeItem('paywall/loggedIn')
      mutations.SET_METER_EXPIRED(currentState)
    }
  },
  SET_DISPLAY_NAME(currentState, displayName) {
    currentState.displayName = displayName
    localStorage.removeItem('paywall/displayName')
    if (displayName) {
      localStorage.setItem('paywall/displayName', currentState.displayName)
    }
  },
  SET_USER_REF(currentState, userRef) {
    currentState.userRef = userRef
    if (process.client) {
      localStorage.removeItem('paywall/userRef')
      if (userRef) {
        localStorage.setItem('paywall/userRef', currentState.userRef)
      }
    }
  },
  SET_CAPABILITIES(currentState, capabilities) {
    currentState.capabilities = capabilities
    localStorage.removeItem('paywall/capabilities')
    if (capabilities) {
      localStorage.setItem(
        'paywall/capabilities',
        JSON.stringify(currentState.capabilities)
      )
    }
  },
  SHOW_LOGIN_FORM(currentState, onSuccessCallback) {
    mutations.HIDE_REGISTRATION_FORM(currentState)
    mutations.HIDE_FORGOT_PASSWORD_FORM(currentState)
    mutations.HIDE_VOL_INTEGRATION(currentState)
    mutations.HIDE_ABOCARD_ACTIVATION(currentState)
    mutations.HIDE_UPSELL(currentState)
    currentState.showLoginForm = true
    currentState.onSuccessCallback = onSuccessCallback
  },
  HIDE_LOGIN_FORM(currentState) {
    currentState.showLoginForm = false
  },
  FETCHING_DATA(currentState, isFetching) {
    currentState.fetchingData = isFetching
  },
  SHOW_REGISTRATION_FORM(currentState, onSuccessCallback) {
    mutations.HIDE_LOGIN_FORM(currentState)
    mutations.HIDE_FORGOT_PASSWORD_FORM(currentState)
    mutations.HIDE_VOL_INTEGRATION(currentState)
    mutations.HIDE_ABOCARD_ACTIVATION(currentState)
    mutations.HIDE_UPSELL(currentState)
    currentState.showRegistrationForm = true
    currentState.onSuccessCallback = onSuccessCallback
  },
  HIDE_REGISTRATION_FORM(currentState) {
    currentState.showRegistrationForm = false
  },
  SHOW_VOL_INTEGRATION(currentState, onSuccessCallback) {
    mutations.HIDE_LOGIN_FORM(currentState)
    mutations.HIDE_REGISTRATION_FORM(currentState)
    mutations.HIDE_FORGOT_PASSWORD_FORM(currentState)
    mutations.HIDE_ABOCARD_ACTIVATION(currentState)
    mutations.HIDE_UPSELL(currentState)
    currentState.showVOLIntegration = true
    currentState.onSuccessCallback = onSuccessCallback
  },
  HIDE_VOL_INTEGRATION(currentState) {
    currentState.showVOLIntegration = false
  },
  SHOW_ABOCARD_ACTIVATION(currentState, data) {
    mutations.HIDE_LOGIN_FORM(currentState)
    mutations.HIDE_REGISTRATION_FORM(currentState)
    mutations.HIDE_FORGOT_PASSWORD_FORM(currentState)
    mutations.HIDE_VOL_INTEGRATION(currentState)
    mutations.HIDE_UPSELL(currentState)
    currentState.showAbocardActivation = true
    currentState.aboCardActivationData = data
  },
  HIDE_ABOCARD_ACTIVATION(currentState) {
    currentState.showAbocardActivation = false
  },
  SHOW_UPSELL(currentState) {
    mutations.HIDE_LOGIN_FORM(currentState)
    mutations.HIDE_REGISTRATION_FORM(currentState)
    mutations.HIDE_FORGOT_PASSWORD_FORM(currentState)
    mutations.HIDE_VOL_INTEGRATION(currentState)
    mutations.HIDE_ABOCARD_ACTIVATION(currentState)
    currentState.showUpsell = true
  },
  HIDE_UPSELL(currentState) {
    currentState.showUpsell = false
  },
  STARTING_EXPERIENCE(currentState) {
    currentState.showOfferContainer = true
  },
  ENDING_EXPERIENCE(currentState) {
    paywallLog(`ending experience Workflow`, {
      showOfferContainer: currentState.showOfferContainer,
      showingOffer: currentState.showingOffer,
    })
    currentState.showOfferContainer = currentState.showingOffer
  },
  SHOW_DATA(currentState) {
    currentState.showData = true
  },
  HIDE_DATA(currentState) {
    currentState.showData = false
  },
  SHOW_EMAIL_HINT(currentState) {
    currentState.showEmailHint = true
  },
  HIDE_EMAIL_HINT(currentState) {
    currentState.showEmailHint = false
  },
  SHOW_FORGOT_PASSWORD_FORM(currentState) {
    mutations.HIDE_REGISTRATION_FORM(currentState)
    mutations.HIDE_LOGIN_FORM(currentState)
    mutations.HIDE_VOL_INTEGRATION(currentState)
    mutations.HIDE_ABOCARD_ACTIVATION(currentState)
    mutations.HIDE_UPSELL(currentState)
    currentState.showForgotPasswordForm = true
  },
  HIDE_FORGOT_PASSWORD_FORM(currentState) {
    currentState.showForgotPasswordForm = false
  },
  OFFER_DISPLAYMODE(currentState, displayMode) {
    currentState.displayMode = displayMode
  },
  IS_BOT_REQUEST(currentState) {
    currentState.isBot = true
  },
  SET_PREFILL_REGISTRATION_DATA(currentState, data) {
    currentState.prefillRegistrationData = data
  },
  SET_ABO_NO(currentState, data) {
    currentState.aboNo = data
  },
}

export const actions = {
  initialize({ commit, dispatch }) {
    commit(
      'SET_PAYWALL_ENABLED',
      !this.$config.isMaintenanceMode && this.$config.paywallEnabled
    )
    let loggedIn = !!localStorage.getItem('paywall/loggedIn')
    let displayName = localStorage.getItem('paywall/displayName')
    let userRef = localStorage.getItem('paywall/userRef')
    const aboNo = localStorage.getItem('paywall/aboNo')
    const subscriber = JSON.parse(localStorage.getItem('paywall/subscriber'))
    let capabilities = JSON.parse(localStorage.getItem('paywall/capabilities'))

    const vnExchangeCookie = this.$cookies.get('_vn_ex')
    if (typeof vnExchangeCookie === 'object') {
      const exchData = vnExchangeCookie
      loggedIn = true
      displayName = exchData.displayName
      userRef = exchData.userRef
      capabilities = exchData.capabilities
    }

    paywallLog(`initialize is called`, {
      loggedIn,
      displayName,
      userRef,
    })

    commit('SET_LOGGED_IN', loggedIn)
    commit('SET_DISPLAY_NAME', displayName)
    commit('SET_USER_REF', userRef)
    commit('SET_ABO_NO', aboNo)
    commit('SET_SUBSCRIBER', subscriber)
    commit('SET_CAPABILITIES', capabilities)
    if (userRef) {
      paywallLog(`generating new jwt`)
      dispatch('loginToTheBackend', userRef)
    } else {
      this.$cookies.remove('jwt')
    }
  },
  async executeExperience({ getters, commit, dispatch }, post) {
    if (!getters.paywallEnabled) {
      return false
    }
    const tp = window.tp
    tp.push(['setUserRef', getters.getUserRef])

    if (post) {
      let toPiano = [
        ...(post.categories || { nodes: [] }).nodes.map((c) => c.slug),
        ...(post.tags || { nodes: [] }).nodes.map((t) => t.slug),
      ]

      toPiano = await applyFilters('piano/customTags', toPiano)
      paywallLog('toPiano', toPiano)
      tp.push(['setTags', toPiano])
      const author = post.author
      if (author) {
        tp.push(['setContentAuthor', author.display_name])
      }
    }
    await dispatch('setCustomVariables')

    commit('STARTING_EXPERIENCE')

    if (tp.experience) {
      paywallLog(`Executing piano experience`)
      tp.experience.execute()
    }
  },
  async setCustomVariables({ getters }) {
    const tp = window.tp

    const capabilities = getters.getCapabilities
    if (capabilities) {
      Object.entries(capabilities).forEach(([key, value]) => {
        tp.push(['setCustomVariable', key, value])
      })
    }
    const customVariables = await applyFilters('piano/customVariables', [])
    customVariables.forEach(({ name, value }) => {
      tp.push(['setCustomVariable', name, value])
    })
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userInSegment({ commit }, data) {
    paywallLog(`user in segment`, data)
  },
  updateMeterData({ commit }, data) {
    commit('SET_METER_DATA', data)
  },
  meterExpired({ commit }, data) {
    paywallLog(`meter has expired`, data)
    commit('SET_METER_EXPIRED', true)
  },
  showingPianoOffer({ dispatch }, data) {
    paywallLog(`will be showing offer`, data)
    dispatch('showingPianoOfferOrTemplate', data)
  },
  unlockArticle({ commit }) {
    commit('SHOWING_OFFER', false)
    commit('ENDING_EXPERIENCE')
  },
  showingPianoTemplate({ dispatch }, data) {
    paywallLog(`will be showing template`, data)
    dispatch('showingPianoOfferOrTemplate', data)
  },
  showingPianoOfferOrTemplate({ commit, dispatch, rootGetters }, data) {
    commit('SHOWING_OFFER', true)
    commit('OFFER_DISPLAYMODE', data.displayMode)
    commit('ENDING_EXPERIENCE')
    const currentPost = rootGetters['news/currentPost']
    dispatch(
      'tracking/trackGeneric',
      {
        category: 'paywall',
        action: 'displayed',
        label: currentPost ? currentPost.title : document.title,
        nonInteraction: true,
      },
      { root: true }
    )
  },
  async setExperienceData({ commit }, data) {
    paywallLog(`got experience data`, data)
    commit('ENDING_EXPERIENCE')
    commit('SET_SUBSCRIBER', {
      ...data.user,
      expired: isUserExpired(data.accessList),
      accessList: data.accessList,
    })
    await doAction('paywall/experienceDataSet', { store: this })
  },
  hidePianoOffers({ commit, getters }) {
    paywallLog(`hiding piano offers on navigation`)
    document.body.classList.remove('tp-modal-open')
    if (getters.displayMode === 'modal') {
      tp.offer.close()
    } else {
      const backdrop = document.querySelector('.tp-backdrop')
      if (backdrop) backdrop.remove()
    }
    const modal = document.querySelector('.tp-modal')
    if (modal) modal.remove()
    commit('SHOWING_OFFER', false)
    commit('HIDE_LOGIN_FORM')
    commit('HIDE_REGISTRATION_FORM')
    commit('HIDE_VOL_INTEGRATION')
    commit('HIDE_FORGOT_PASSWORD_FORM')
    commit('HIDE_ABOCARD_ACTIVATION')
    commit('HIDE_UPSELL')
  },
  showLoginFormForDisplayMode({ dispatch, getters }, data) {
    // if the paywall is inline the data passed by piano is in a different format than when the paywall displays as
    // modal. modal delivers all parameters in data, inline in data.data
    this.$cookies.set('__pianoParams', data)
    data = data.data || data
    if (getters.displayMode === 'modal') {
      dispatch('showLoginOverlay', {
        ...data,
        modalType: 'no-transition',
      })
    } else if (data.displayMode === 'modal') {
      dispatch('showLoginOverlay', {
        ...data,
      })
    } else {
      dispatch('showLoginForm', data)
    }
  },
  showLoginForm({ commit, dispatch }, data) {
    paywallLog(`should show login form, got data`, data)
    commit('SHOW_LOGIN_FORM', data ? data.callback : undefined)
    dispatch('tracking/trackPaywallAction', 'showlogin', { root: true })
  },
  showRegistrationForm({ commit, dispatch }, data) {
    paywallLog(`should show registration form, got data`, data)
    commit('SHOW_REGISTRATION_FORM', data ? data.callback : undefined)
    dispatch('tracking/trackPaywallAction', 'testclick', { root: true })
  },
  showVOLIntegration({ commit, dispatch }, data) {
    paywallLog(`should show vol integration, got data`, data)
    commit('SHOW_VOL_INTEGRATION', data ? data.callback : undefined)
    dispatch('tracking/trackPaywallAction', 'laendlepunkte', { root: true })
  },
  showAbocardActivation({ commit, dispatch }, data) {
    paywallLog(`should show abocard activation, gat data`, data)
    commit(`SHOW_ABOCARD_ACTIVATION`, data)
    dispatch(`tracking/trackPaywallAction`, `activation`, { root: true })
  },
  showUpsell({ commit, dispatch }) {
    paywallLog(`should show upsell`)
    commit(`SHOW_UPSELL`)
    dispatch(`tracking/trackPaywallAction`, `upsell`, { root: true })
  },
  showForgotPasswordForm({ commit, dispatch }) {
    paywallLog(`should show forgot password form`)
    commit('SHOW_FORGOT_PASSWORD_FORM')
    dispatch('tracking/trackPaywallAction', 'forgotpassword', { root: true })
  },
  async login(context, { username, password, loginFromApp = false }) {
    await performLogin(context, username, password, loginFromApp, this.$config)
  },
  async activateAbocard(context, data) {
    await performActivation(context, data, this.$config)
  },
  async preRegister(
    { commit },
    { firstname, lastname, email, phone, password, agbs }
  ) {
    commit('FETCHING_DATA', true)
    try {
      const res = await fetch(`${this.$config.paywallEndpoint}/pre_register`, {
        method: 'POST',
        body: JSON.stringify({
          firstname,
          lastname,
          email,
          phone_alias: phone,
          password,
          agbs,
        }),
      })
      commit('FETCHING_DATA', false)
      const data = await res.json()
      if (data.error) {
        throw new Error(data.error.message)
      }
    } catch (err) {
      commit('FETCHING_DATA', false)
      throw err
    }
  },
  async register(
    { commit, dispatch },
    { firstname, lastname, email, phone, password, agbs, token }
  ) {
    commit('FETCHING_DATA', true)
    try {
      const res = await fetch(`${this.$config.paywallEndpoint}/register`, {
        method: 'POST',
        body: JSON.stringify({
          firstname,
          lastname,
          email,
          phone_alias: phone,
          password,
          agbs,
          token,
        }),
      })
      commit('FETCHING_DATA', false)
      const json = await res.json()
      if (!json.error) {
        await dispatch('handlePaywallResponse', {
          ...json,
          fromRegistration: true,
          registerData: { firstname, lastname, email, phone, agbs },
        })
      }

      return await json
    } catch (err) {
      commit('FETCHING_DATA', false)
      throw err
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async forgotPassword({ commit, dispatch }, { email }) {
    const res = await fetch(
      `${this.$config.paywallEndpoint}/sso_forgot_password`,
      {
        method: 'POST',
        body: JSON.stringify({
          login: email,
        }),
      }
    )

    const data = await res.json()
    if (!data.success && data.message) {
      throw new Error(data.message)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async validateForgotPassword({ commit, dispatch }, { h }) {
    try {
      const res = await fetch(
        `${this.$config.paywallEndpoint}/sso_validate_password`,
        {
          method: 'POST',
          body: JSON.stringify({
            h,
          }),
        }
      )
      return res
    } catch (e) {
      paywallError('Exception occurred', e)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getAgbList({ commit, dispatch }) {
    try {
      const res = await fetch(`${this.$config.paywallEndpoint}/sso_agb_list`, {
        method: 'GET',
      })
      return res.json()
    } catch (e) {
      paywallError('Exception occurred', e)
    }
  },
  async checkIfEmailAlreadyExists({ commit }, { email }) {
    try {
      const res = await fetch(`${this.$config.paywallEndpoint}/check_login`, {
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      })

      const json = await res.json()

      if (json.exists) {
        commit('SHOW_EMAIL_HINT')
      } else {
        commit('HIDE_EMAIL_HINT')
      }
    } catch (e) {
      paywallError('Exception occurred', e)
    }
  },
  async handlePaywallResponse({ commit, dispatch }, data) {
    if (data.error) {
      throw new Error(data.error.message)
    }
    commit('SET_LOGGED_IN', true)
    commit('SET_DISPLAY_NAME', data.displayName)
    commit('SET_USER_REF', data.userRef)
    commit('SET_CAPABILITIES', data.capabilities)

    await dispatch('setCustomVariables')

    // pass the city data to the weather store to fetch the data
    dispatch(
      'weather/setRegion',
      { region: data.city, overwrite: false },
      { root: true }
    )

    if (!data.fromRegistration) {
      await dispatch('loginToTheBackend', data.userRef)
      this.$cookies.set(
        `vmhsso`,
        `h=${data.hash}&l=${data.email || ''}&t=${data.token}`,
        {
          domain: this.$config.pianoCookieDomain,
          maxAge: 365 * 24 * 60 * 60,
          path: '/',
        }
      )
      this.$cookies.set(
        `_vn_ex`,
        {
          displayName: data.displayName,
          userRef: data.userRef,
          capabilities: data.capabilities,
        },
        {
          domain: this.$config.pianoCookieDomain,
          maxAge: 365 * 24 * 60 * 60,
          path: '/',
        }
      )
      await doAction('paywall/loginSuccess', {
        store: this,
        userRef: data.userRef,
        ...data,
      })
    }
    await dispatch('runOnSuccessCallback', { userRef: data.userRef, ...data })
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async loginToTheBackend({ commit }, userRef) {
    const mutation = require('~base/graphql/mutations/AuthorizeWithPiano.gql')
    try {
      const apolloClient = this.app.apolloProvider.defaultClient
      const response = await apolloClient.mutate({
        mutation,
        variables: {
          userRef,
        },
      })
      if (response.data.authorizeWithPiano.access) {
        this.$cookies.set('jwt', response.data.authorizeWithPiano.token)
      }
    } catch (e) {
      console.error(`🔒 error authenticating on backend`, e)
    }
  },
  showLoginInModalPaywall({ dispatch }) {
    dispatch('showLoginForm')
    dispatch(
      'modal/showModal',
      { modalType: 'invisible', slotClass: 'md:max-w-paywall' },
      { root: true }
    )
  },
  showLoginOverlay({ dispatch }, data) {
    dispatch('showLoginForm', data)
    dispatch(
      'modal/showModal',
      { slotClass: 'md:max-w-paywall', ...data },
      { root: true }
    )
  },
  showRegistrationOverlay({ dispatch, rootGetters }, data) {
    if (rootGetters['app/isApp']) {
      dispatch('app/openRegistrationView', null, { root: true })
    } else {
      dispatch('showRegistrationForm', data)
      dispatch(
        'modal/showModal',
        { slotClass: 'md:max-w-paywall', ...data },
        { root: true }
      )
    }
  },
  showDataOverlay({ commit, dispatch }, data) {
    paywallLog(`should show login form, got data`, data)
    commit('HIDE_LOGIN_FORM')
    commit('HIDE_REGISTRATION_FORM')
    commit('HIDE_VOL_INTEGRATION')
    commit('HIDE_FORGOT_PASSWORD_FORM')
    commit('HIDE_ABOCARD_ACTIVATION')
    commit('HIDE_UPSELL')
    if (window.tp) {
      commit('SHOW_DATA')
      dispatch(
        'modal/showModal',
        { slotClass: 'w-full md:max-w-xl', ...data },
        { root: true }
      )
      setTimeout(() => {
        window.tp.myaccount.show({
          modal: 'inline',
          containerSelector: '.piano-my-account',
        })
      }, 500)
    }
  },
  hidePaywall({ commit, dispatch, rootGetters }) {
    commit('HIDE_LOGIN_FORM')
    commit('HIDE_REGISTRATION_FORM')
    commit('HIDE_VOL_INTEGRATION')
    commit('HIDE_FORGOT_PASSWORD_FORM')
    commit('HIDE_ABOCARD_ACTIVATION')
    commit('HIDE_UPSELL')
    if (rootGetters['modal/isModalShowing']) {
      dispatch('modal/hideModal', null, { root: true })
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hideOverlay({ commit, dispatch }) {},
  async handleCustomEvent({ dispatch }, data) {
    paywallLog(`custom event triggered`, data)
    if (data.eventName && data.eventName.indexOf('offer-') === 0) {
      dispatch('showRegistrationForm')
    } else if (data.eventName === 'validateDebitPayment') {
      dispatch('handleDebitPaymentMethod', data.params)
    } else if (data.eventName === 'validateInvoicePayment') {
      dispatch('handleInvoicePaymentMethod', data.params)
    } else if (data.eventName === 'rewardItemCheck') {
      dispatch('handleRewardItemCheck', data.params)
    } else if (data.eventName === 'storeUserData') {
      dispatch('handleStoreUserData', data.params)
    } else if (data.eventName === 'orderUpsell') {
      dispatch('handleUpsellPayment', data.params)
    } else if (data.eventName === 'closeMeterIntroTemplate') {
      window.location.reload()
    } else if (data.eventName === 'closeAppUpsellConfirmation') {
      await dispatch('app/authorizeApp', null, { root: true })
      dispatch('app/loginWithAPA', null, { root: true })
    } else if (
      data.eventName &&
      data.eventName.indexOf('reward-offer-') === 0
    ) {
      dispatch('showVOLIntegration')
    }
  },
  handleMessageEvent({ dispatch }, data) {
    paywallLog(`message event received`, data)
    if (data.message === 'RMD_PASSWORD_CHANGE') {
      dispatch('changeSSOPassword', data)
    } else if (data.message === 'DIRECT_DEBIT_AUTORENEW_TOGGLE') {
      dispatch('debitAutoRenewToggle', data)
    }
  },
  async handleDebitPaymentMethod({ getters, dispatch }, params) {
    const { firstname, lastname, iban } = params

    const eventParams = JSON.parse(params.params)
    const rawData = await fetch(
      `${this.$config.paywallEndpoint}/debit_payment`,
      {
        method: 'POST',
        body: JSON.stringify({
          userRef: getters.getUserRef,
          firstname,
          lastname,
          iban,
        }),
      }
    )
    if (rawData.status === 200) {
      const data = await rawData.json()
      dispatch('postToPianoIframe', {
        iframeId: eventParams.iframeId,
        success: data.success,
        message: data.message,
        object: data.object,
      })
    } else {
      throw new Error(rawData.statusText)
    }
  },
  async handleInvoicePaymentMethod({ getters, dispatch }, params) {
    const { firstname, lastname } = params

    const eventParams = JSON.parse(params.params)
    const rawData = await fetch(
      `${this.$config.paywallEndpoint}/invoice_payment`,
      {
        method: 'POST',
        body: JSON.stringify({
          userRef: getters.getUserRef,
          firstname,
          lastname,
        }),
      }
    )
    if (rawData.status === 200) {
      const data = await rawData.json()
      dispatch('postToPianoIframe', {
        iframeId: eventParams.iframeId,
        success: data.success,
        message: data.message,
        object: data.object,
      })
    } else {
      throw new Error(rawData.statusText)
    }
  },
  async handleRewardItemCheck({ getters, dispatch }, params) {
    const {
      termid,
      finalize = false,
      firstname = null,
      lastname = null,
    } = params
    const eventParams = JSON.parse(params.params)
    const tp = window.tp

    const rawData = await fetch(
      `${this.$config.paywallEndpoint}/check_reward_item`,
      {
        method: 'POST',
        body: JSON.stringify({
          userRef: getters.getUserRef,
          termid,
          voluserid: tp.customVariables.vol_user,
          finalize,
          firstname,
          lastname,
        }),
      }
    )

    if (rawData.status === 200) {
      const data = await rawData.json()
      dispatch('postToPianoIframe', {
        iframeId: eventParams.iframeId,
        success: data.success,
        message: data.message,
        object: data.object,
      })
    } else {
      throw new Error(rawData.statusText)
    }
  },
  async handleStoreUserData({ getters, dispatch }, params) {
    const { firstname = null, lastname = null } = params
    const eventParams = JSON.parse(params.params)
    const rawData = await fetch(
      `${this.$config.paywallEndpoint}/store_user_data`,
      {
        method: 'POST',
        body: JSON.stringify({
          userRef: getters.getUserRef,
          firstname,
          lastname,
        }),
      }
    )

    if (rawData.status === 200) {
      const data = await rawData.json()
      dispatch('postToPianoIframe', {
        iframeId: eventParams.iframeId,
        success: data.success,
        message: data.message,
        object: data.object,
      })
    } else {
      throw new Error(rawData.statusText)
    }
  },
  async handleUpsellPayment({ getters, dispatch }, params) {
    const { campno } = params
    const eventParams = JSON.parse(params.params)

    const rawData = await fetch(
      `${this.$config.paywallEndpoint}/upsell_payment`,
      {
        method: 'POST',
        body: JSON.stringify({
          userRef: getters.getUserRef,
          campno,
        }),
      }
    )
    if (rawData.status === 200) {
      const data = await rawData.json()

      dispatch('postToPianoIframe', {
        iframeId: eventParams.iframeId,
        success: data.success,
        message: data.message,
        object: data.object,
      })
    } else {
      throw new Error(rawData.statusText)
    }
  },
  async debitAutoRenewToggle({ dispatch }, { data }) {
    const { accessId, uid, iframeId, enabled, cancelReason } = data

    const rawData = await fetch(
      `${this.$config.paywallEndpoint}/debit_auto_renew_toggle`,
      {
        method: 'POST',
        body: JSON.stringify({
          accessId,
          uid,
          enabled,
          cancelReason,
        }),
      }
    )
    if (rawData.status === 200) {
      const data = await rawData.json()
      dispatch('postToPianoIframe', {
        iframeId,
        success: data.success,
        message: data.message,
        object: data.object,
      })
    } else {
      throw new Error(rawData.statusText)
    }
  },
  async changeSSOPassword({ dispatch }, { data }) {
    const {
      uid,
      iframeId,
      isChangingPassword,
      currentPassword = '',
      newPassword = '',
      newPasswordConfirmation = '',
    } = data

    if (!isChangingPassword) return

    const rawData = await fetch(
      `${this.$config.paywallEndpoint}/sso_change_password`,
      {
        method: 'POST',
        body: JSON.stringify({
          uid,
          currentPassword,
          newPassword,
          newPasswordConfirmation,
        }),
      }
    )
    if (rawData.status === 200) {
      const data = await rawData.json()
      dispatch('postToPianoIframe', {
        iframeId,
        success: data.success,
        message: data.message,
        object: data.object,
      })
    } else {
      throw new Error(rawData.statusText)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  postToPianoIframe(context, data) {
    const iFrame = document.getElementById(data.iframeId)
    if (iFrame) {
      iFrame.contentWindow.postMessage(
        {
          piano: {
            success: data.success,
            message: data.message,
            object: data.object,
          },
        },
        '*'
      )
    }
  },
  async clearSubscriberData({ commit }) {
    commit('SET_LOGGED_IN', false)
    commit('SET_SUBSCRIBER', undefined)
    commit('SET_USER_REF', undefined)
    commit('SET_CAPABILITIES', undefined)

    this.$cookies.remove('__ut')
    this.$cookies.remove('__ut', {
      path: '/',
      domain: this.$config.pianoCookieDomain,
    })

    this.$cookies.remove('_vn_ex')
    this.$cookies.remove('_vn_ex', {
      path: '/',
      domain: this.$config.pianoCookieDomain,
    })

    const ssoCookie = this.$cookies.get('vmhsso')
    this.$cookies.remove('vmhsso')
    this.$cookies.remove('vmhsso', {
      path: '/',
      domain: this.$config.pianoCookieDomain,
    })

    this.$cookies.remove('jwt')

    try {
      if (ssoCookie) {
        const [hash, token] = ssoCookie
          .split('&')
          .map((i) => i.split('='))
          .filter((a) => a[0] !== 'l')
          .map((a) => a[1])
        await fetch(`${this.$config.paywallEndpoint}/logout`, {
          method: 'POST',
          body: JSON.stringify({
            hash,
            token,
          }),
        })
      }
    } catch (e) {
      paywallError('Exception occurred', e)
    }
    paywallLog(`cleared susbcriber data.`)
  },
  async logout({ dispatch }) {
    paywallLog('Logging out...')
    window.tp.user.logout()
    window.tp.push(['setUserRef', ''])
    await dispatch('clearSubscriberData')

    window.location.reload()
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async runOnSuccessCallback(context, data) {
    const tp = window.tp
    tp.push(['setUserRef', data.userRef])
    await tp.push([
      'init',
      () => {
        if (!data.loginFromApp) {
          tp.experience.execute()
        }

        if (data.fromRegistration) {
          let params
          if (tp.user.isUserValid()) {
            const paramsCookie = tp.util.findCookieByName('__pianoParams')
            try {
              params = JSON.parse(paramsCookie)
              this.$cookies.remove('__pianoParams')
            } catch (e) {
              params = false
            }
          }
          paywallLog(`got params`, params)
          if (params) {
            tp.offer.startCheckout(params.data)
          }
        }
      },
    ])
  },
  async checkoutComplete({ getters, dispatch }, { data }) {
    paywallLog(`checkout complete`, data)
    if (data.state === 'checkoutCompleted') {
      await dispatch('loginToTheBackend', getters.getUserRef)
      window.location.reload()
    }
  },

  async connectToVOL({ dispatch, commit }) {
    commit('FETCHING_DATA', true)
    try {
      const volCookie = this.$cookies.get('vol-integration')
      if (!volCookie) {
        throw new Error('Not authenticated on VOL.at')
      }

      const { ssoHash: hash, ssoToken: token } = volCookie
      const volResponse = await fetch(`/autologin?h=${hash}&t=${token}`)
      let response = await volResponse.json()
      const {
        additional_data: {
          sso: {
            user_data: { email, md5passwd, user_id: userId },
          },
        },
      } = await response

      const paywallResponse = await fetch(
        `${this.$config.paywallEndpoint}/sso_id_login`,
        {
          method: 'POST',
          body: JSON.stringify({ email, md5passwd, user_id: userId }),
        }
      )

      response = await paywallResponse.json()

      commit('FETCHING_DATA', false)
      await dispatch('handlePaywallResponse', {
        ...response,
        fromRegistration: true,
      })
      return response
    } catch (err) {
      commit('FETCHING_DATA', false)
      throw err
    }
  },
  setUserRef({ commit }, userRef) {
    commit('SET_USER_REF', userRef)
  },
  setIsBotRequest({ commit }) {
    commit('IS_BOT_REQUEST')
  },
  async fetchAboNo({ commit, getters }) {
    if (getters.aboNo) return

    commit('FETCHING_DATA', true)
    try {
      const paywallResponse = await fetch(
        `${this.$config.paywallEndpoint}/userref_login`,
        {
          method: 'POST',
          body: JSON.stringify({ userRef: getters.getUserRef }),
        }
      )
      const response = await paywallResponse.json()
      commit('FETCHING_DATA', false)
      if (response.cusno) {
        commit('SET_ABO_NO', response.cusno)
        localStorage.setItem('paywall/aboNo', response.cusno)
        commit('SET_DISPLAY_NAME', response.displayName)
      } else {
        commit('SET_ABO_NO', undefined)
        localStorage.removeItem('paywall/aboNo')
      }
    } catch (err) {
      commit('FETCHING_DATA', false)
      throw err
    }
  },
}

export const getters = {
  subscriber: (currentState) => currentState.subscriber,
  isLoggedIn: (currentState) => currentState.loggedIn,
  displayName: (currentState) => currentState.displayName,
  aboNo: (currentState) => currentState.aboNo,
  displayShortenedContent: ({ shortenContent }, getters) =>
    getters.paywallEnabled && shortenContent,
  paywallEnabled: ({ enabled }) => {
    return enabled === undefined || enabled
  },
  showingOffer: (currentState) => currentState.showingOffer,
  showOfferContainer: ({ showOfferContainer }, getters) =>
    getters.paywallEnabled && showOfferContainer,
  currentExperience: (currentState) => currentState.experience,
  showLoginForm: (currentState) => currentState.showLoginForm,
  showRegistrationForm: (currentState) => currentState.showRegistrationForm,
  showVOLIntegration: (currentState) => currentState.showVOLIntegration,
  showForgotPasswordForm: (currentState) => currentState.showForgotPasswordForm,
  showAbocardActivation: (currentState) => currentState.showAbocardActivation,
  showUpsell: (currentState) => currentState.showUpsell,
  showData: ({ showData }) => showData,
  showEmailHint: (currentState) => currentState.showEmailHint,
  fetchingData: (currentState) => currentState.fetchingData,
  getUserRef: ({ userRef }) => userRef,
  getCapabilities: ({ capabilities }) => capabilities,
  onSuccessCallback: ({ onSuccessCallback }) => onSuccessCallback,
  showInternalForm: ({
    loggedIn,
    showLoginForm,
    showRegistrationForm,
    showForgotPasswordForm,
  }) =>
    !loggedIn &&
    (showLoginForm || showRegistrationForm || showForgotPasswordForm),
  displayMode: ({ displayMode }) => displayMode,
  kayakLocation: ({ kayakLocation }) => kayakLocation,
  isBot: ({ isBot }) => isBot,
  aboCardActivationData: ({ aboCardActivationData }) => aboCardActivationData,
  hasValidSubscription: (_, getters) =>
    getters.isLoggedIn &&
    getters.aboNo !== undefined &&
    getters.displayName !== undefined,
  prefillRegistrationData: ({ prefillRegistrationData }) =>
    prefillRegistrationData,
}
